import { PATH_IMAGES, PATH_BANK_URL } from '~/constants/path'

// CONSTANTS INFO

export const USER_INFO = {
  WALLET: {
    text: 'Ví của tôi:',
    icon: 'icon-wallet.svg'
  },
  ROLLING: {
    text: 'Cược yêu cầu:',
    icon: 'icon-bet.svg',
    note: 'Doanh thu vòng cược cần hoàn thành trước khi rút tiền'
  }
}

// CONSTANTS PROFILE
const PATH_PROFILE = 'images/account/profile/'
const PATH_ICON = 'images/icon/'

export const TEXT_CONTENT = {
  PROFILE_USER: 'Quản lý tài khoản',
  PROFILE_BANK: 'Quản lý ngân hàng'
}

export const PROFILE_TABS = [
  {
    text: TEXT_CONTENT.PROFILE_USER,
    icon: 'icon-profile',
    name: 'profile'
  },
  {
    text: TEXT_CONTENT.PROFILE_BANK,
    icon: 'icon-bank',
    name: 'bank'
  }
]

export const FORM_INFO = {
  LABEL_USERNAME: 'Tên đăng nhập',
  PLACEHOLDER_USERNAME: 'Nhập tên đăng nhập',
  LABEL_DISPLAY: 'Tên hiển thị',
  PLACEHOLDER_DISPLAY: 'Tên trong game',
  LABEL_PHONE: 'Số điện thoại',
  PLACEHOLDER_PHONE: 'Nhập số điện thoại',
  ERROR_PHONE: 'Vui lòng không nhập ít hơn 10 chữ số',
  LABEL_EMAIL: 'Email',
  PLACEHOLDER_EMAIL: 'Nhập email của bạn',
  LABEL_PASS: 'Mật khẩu',
  PLACEHOLDER_PASS: 'Nhập mật khẩu',
  LABEL_PASSOLD: 'Mật khẩu hiện tại',
  PLACEHOLDER_PASSOLD: 'Nhập mật khẩu hiện tại',
  LABEL_PASSNEW: 'Mật khẩu mới',
  LABEL_PASSCONFIRM: 'Nhập lại mật khẩu mới',
  TEXT_SUBMIT: 'Lưu thay đổi',
  TEXT_CONFIRM: 'Xác thực',
  TEXT_WAIT_CONFIRM: 'Chờ xác thực',
  TEXT_CHANGE_PASS: 'Thay đổi mật khẩu',
  PATH_ICON_DONE: `${PATH_ICON}check-done.svg`,
  PATH_BANK_EMPTY: `${PATH_PROFILE}bank/empty-bank.webp`,
  PATH_ICON_ADD: `${PATH_ICON}add.svg`,
  DESC_BANK_EMPTY: 'Quý khách vui lòng thêm tài khoản ngân hàng chính chủ để có thể thực hiện Nạp/Rút tiền.',
  TEXT_ADD_BANK: 'Thêm tài khoản ngân hàng',
  TEXT_ADD_BANK_2: 'Thêm ngân hàng',
  LABEL_NAME_BANK: 'Ngân hàng',
  PLACEHOLDER_BANK: 'Thêm ngân hàng của bạn',
  LABEL_USER_BANK: 'Tên chủ thẻ',
  PLACEHOLDER_USER_BANK: 'Nhập tên chủ thẻ',
  LABEL_NUMBER_BANK: 'Số tài khoản',
  PLACEHOLDER_NUMBER_BANK: 'Nhập số tài khoản',
  MASK_NUMBER: '00000000000000000000',
  TEXT_BUTTON_ADD_BANK: 'Thêm ngân hàng',
  TEXT_BUTTON_CONFIRM: 'Xác nhận',
  TEXT_BUTTON_CLOSE: 'Đóng',
  LOGO_BANK_DEFAULT: `${PATH_BANK_URL}default.svg`,
  NOTI_FULL_BANK:
    'Bạn đã thêm 5 tài khoản ngân hàng chính chủ. Trong trường hợp bạn muốn đổi ngân hàng, vui lòng liên hệ bộ phận <span>CSKH</span> của chúng tôi.',
  ICON_FULL_BANK: `${PATH_ICON}warning-outline.svg`
}

// CONSTANTS INFORMATIONS

export const VERIFY_PHONE = {
  TITLE_FORM: 'Xác thực số điện thoại',
  MACOS_APP: 'Get Telegram for macOS',
  WINDOW_APP: 'Get Telegram for Windows',
  TEXT_NEXT: 'Tiếp theo',
  SUBMIT_NOW: 'Xác thực'
}

export function STEP_ACTIVE_PHONE() {
  const { $config } = useNuxtApp()
  return [
    {
      title: 'Bước 1. Tải & mở ứng dụng Telegram',
      desc: 'Tải hoặc mở ứng dụng Telegram',
      image: `${PATH_PROFILE}telegram-logo.svg`,
      google: `${PATH_PROFILE}google-play.svg`,
      app: `${PATH_PROFILE}app-store.svg`
    },
    {
      title: 'Bước 2. Tạo tài khoản & đăng nhập Telegram ',
      desc: 'Tạo tài khoản bằng số điện thoại đã đăng kí',
      image: `${PATH_PROFILE}active-phone-step-2.webp`
    },
    {
      title: `Bước 3. Chat với Bot ${$config.BRANCH_NAME} để xác thực `,
      desc: [
        'Ấn vào nút Start để bắt đầu chat với Bot',
        'Ấn vào /verify để nhập mã xác thực SĐT',
        ' Ấn vào chia sẻ số điện thoại'
      ],
      video: `/${PATH_PROFILE}user-chat-with-bot-tele.mp4`,
      image: `${PATH_PROFILE}active-phone-step-3.webp`
    }
  ]
}

// CONSTANTS VERIFY EMAIL
export const VERIFY_EMAIL = {
  TITLE_FORM: 'Xác Thực Email',
  GO_HOME: 'Về trang chủ',
  RESEND: 'Xác thực lại',
  TEXT_SUCCESS: 'Xác thực email thành công',
  TEXT_FAIL: 'Xác thực email thất bại',
  TEXT_EXPIRED: 'Hết hạn xác thực',
  ICON_SECCESS: `${PATH_PROFILE}email-success.webp`,
  ICON_FAIL: `${PATH_PROFILE}email-fail.webp`,
  ICON_EXPIRED: `${PATH_PROFILE}email-wait.webp`
}
